import React, { useEffect, useState } from 'react';
import { getDatabase, ref, get } from 'firebase/database';

const HaikooRead = ({ haikooID }) => {
  const [haikooData, setHaikooData] = useState(null);

  useEffect(() => {
    // Reference to the haikoo document in the Realtime Database
    const db = getDatabase();
    const haikooRef = ref(db, `Haikoos/${haikooID}`);

    // Fetch the haikoo data
    get(haikooRef)
      .then((snapshot) => {
        if (snapshot.exists()) {
          const haikoo = snapshot.val();
          setHaikooData(haikoo);
        } else {
          console.log(`Haikoo with ID ${haikooID} not found in the Realtime Database.`);
        }
      })
      .catch((error) => {
        console.error('Error fetching haikoo data:', error);
      });
  }, [haikooID]);

  // Conditional rendering based on whether haikooData is available
  if (haikooData) {
    return (

      <ul
    
  
      className={"haikoo_cards"}
    >
      <h1 className="title_haikoo">{haikooData.title}</h1>
      
     
      <p className="haikoo_text">"{haikooData.content}"</p>
     
      <br></br>
      <span>
        
        
      <i> by {haikooData.author}</i><img className="miniature_pic" id={haikooData.user_id} /></span>
      <p id="technical_score">{haikooData.score}</p>
      <p id="popular_score">{haikooData.social_score}</p>
      
    </ul>
    );
  } else {
    return (
      <div>
        <p>Loading haikoo data...</p>
        {/* You can render a loading indicator or an error message here */}
      </div>
    );
  }
};

export default HaikooRead;

