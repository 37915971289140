// stripe.js

import { db } from "../firebaseConfig";
import firebase from "firebase/compat/app";
import { getAuth } from "firebase/auth";

const auth = getAuth();
const user = auth.currentUser;
const YOUR_DOMAIN = 'http://haikoo.io'; // Replace with your actual domain
const functionLocation = 'us-east1';
/**
 * Create a Checkout Session
 * @param {string} priceId - The price ID for the product to be purchased
 * @returns {Promise<string>} - The URL for the Stripe Checkout session
 */
export const createCheckoutSession = async (priceId) => {
  const response = await fetch(`${YOUR_DOMAIN}/create-checkout-session`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ priceId }),
  });

  const { url } = await response.json();
  return url;
};

/**
 * Start a Checkout Session for a subscription
 * @param {string} priceId - The price ID for the product to be subscribed to
 */
export const subscribe = async (priceId) => {
  const checkoutSession = {
    automatic_tax: true,
    tax_id_collection: true,
    collect_shipping_address: true,
    allow_promotion_codes: true,
    line_items: [{ price: priceId, quantity: 1 }],
    success_url: `${YOUR_DOMAIN}/return?session_id={CHECKOUT_SESSION_ID}`,
    cancel_url: `${YOUR_DOMAIN}/checkout`,
  };

  const docRef = await db.collection('customers').doc(user).collection('checkout_sessions').add(checkoutSession);
  
  docRef.onSnapshot((snap) => {
    const { error, url } = snap.data();
    if (error) {
      alert(`An error occurred: ${error.message}`);
    }
    if (url) {
      window.location.assign(url);
    }
  });
};

/**
 * Get custom claim role for the current user
 * @returns {Promise<string>} - The custom claim role of the user
 */
export const getCustomClaimRole = async () => {
  const token = await firebase.auth().currentUser.getIdToken(true);
  const decodedToken = await firebase.auth().currentUser.getIdTokenResult();
  return decodedToken.claims.stripeRole;
};

/**
 * Create a billing portal link
 * @returns {Promise<string>} - The URL for the Stripe billing portal
 */
export const createBillingPortalLink = async () => {
  const functionRef = firebase.app().functions(functionLocation).httpsCallable('ext-firestore-stripe-subscriptions-createPortalLink');
  const { data } = await functionRef({ returnUrl: window.location.origin });
  return data.url;
};
