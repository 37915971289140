// src/firebaseConfig.js

import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import { getFunctions } from "firebase/functions";  // Import Firebase Functions


// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyBTh2cWOzmY8wqrqeS5y-uuhnzkG115hGE",
    authDomain: "haikoo-bc326.firebaseapp.com",
    databaseURL:
      "https://haikoo-bc326-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "haikoo-bc326",
    storageBucket: "haikoo-bc326.appspot.com",
    messagingSenderId: "784052480580",
    appId: "1:784052480580:web:9a9e5e50cb8df5def9ea11",
  };
  

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const auth = getAuth(app);
const functions = getFunctions(app);  // Initialize Firebase Functions


export { app, auth, db, functions };  // Export functions along with other services
