// import React, { useEffect, useState } from 'react';
// import firebase from 'firebase/compat/app'; // Ensure you're importing from 'compat'
// import 'firebase/compat/firestore'; // Add Firestore compat import
// import 'firebase/compat/functions'; // Add Functions compat import
// import 'firebase/compat/auth'; // Add Auth compat import

// // Firebase configuration
// const firebaseConfig = {
//   apiKey: "AIzaSyBTh2cWOzmY8wqrqeS5y-uuhnzkG115hGE",
//   authDomain: "haikoo-bc326.firebaseapp.com",
//   databaseURL: "https://haikoo-bc326-default-rtdb.europe-west1.firebasedatabase.app",
//   projectId: "haikoo-bc326",
//   storageBucket: "haikoo-bc326.appspot.com",
//   messagingSenderId: "784052480580",
//   appId: "1:784052480580:web:9a9e5e50cb8df5def9ea11",
// };

// // Replace with your cloud functions location
// const functionLocation = 'us-east1';

// // Initialize Firebase
// if (!firebase.apps.length) {
//   firebase.initializeApp(firebaseConfig);
// }

// const db = firebase.firestore(); // Use Firestore from firebase.compat
// const functions = firebase.functions(); // Use Functions from firebase.compat

// const SubscriptionManager = ({ userId }) => {
//   const [products, setProducts] = useState([]);
//   const [subscription, setSubscription] = useState(null);
//   const prices = {};

//   useEffect(() => {
//     if (userId) {
//       startDataListeners(userId);
//     }
//   }, [userId]);

//   const startDataListeners = (uid) => {
//     const productsContainer = document.querySelector('.products');
//     const template = document.querySelector('#product-template');

//     db.collection('products')
//       .where('active', '==', true)
//       .get()
//       .then((querySnapshot) => {
//         querySnapshot.forEach(async (doc) => {
//           const priceSnap = await doc.ref
//             .collection('prices')
//             .where('active', '==', true)
//             .orderBy('unit_amount')
//             .get();

//           const product = doc.data();
//           const container = template.content.cloneNode(true);

//           container.querySelector('h2').innerText = product.name.toUpperCase();
//           container.querySelector('.description').innerText =
//             product.description?.toUpperCase() || '';

//           priceSnap.docs.forEach((doc) => {
//             const priceId = doc.id;
//             const priceData = doc.data();
//             prices[priceId] = priceData;

//             const content = document.createTextNode(
//               `${new Intl.NumberFormat('en-US', {
//                 style: 'currency',
//                 currency: priceData.currency,
//               }).format((priceData.unit_amount / 100).toFixed(2))} per ${
//                 priceData.interval ?? 'once'
//               }`
//             );
//             const option = document.createElement('option');
//             option.value = priceId;
//             option.appendChild(content);
//             container.querySelector('#price').appendChild(option);
//           });

//           if (product.images.length) {
//             const img = container.querySelector('img');
//             img.src = product.images[0];
//             img.alt = product.name;
//           }

//           const form = container.querySelector('form');
//           form.addEventListener('submit', subscribe);

//           productsContainer.appendChild(container);
//         });
//       });

//     db.collection('customers')
//       .doc(uid)
//       .collection('subscriptions')
//       .where('status', 'in', ['trialing', 'active'])
//       .onSnapshot(async (snapshot) => {
//         if (snapshot.empty) {
//           document.querySelector('#subscribe').style.display = 'block';
//           return;
//         }
//         document.querySelector('#subscribe').style.display = 'none';
//         document.querySelector('#my-subscription').style.display = 'block';

//         const subscription = snapshot.docs[0].data();
//         const priceData = (await subscription.price.get()).data();

//         document.querySelector(
//           '#my-subscription p'
//         ).textContent = `You are paying ${new Intl.NumberFormat('en-US', {
//           style: 'currency',
//           currency: priceData.currency,
//         }).format((priceData.unit_amount / 100).toFixed(2))} per ${
//           priceData.interval
//         }, giving you the role: ${await getCustomClaimRole()}. 🥳`;
//       });
//   };

//   const subscribe = async (event) => {
//     event.preventDefault();
//     document.querySelectorAll('button').forEach((b) => (b.disabled = true));
//     const formData = new FormData(event.target);
//     const selectedPrice = {
//       price: formData.get('price'),
//     };

//     if (prices[selectedPrice.price]?.recurring?.usage_type !== 'metered')
//       selectedPrice.quantity = 1;

//     const checkoutSession = {
//       automatic_tax: true,
//       tax_id_collection: true,
//       collect_shipping_address: true,
//       allow_promotion_codes: true,
//       line_items: [selectedPrice],
//       success_url: window.location.origin,
//       cancel_url: window.location.origin,
//       metadata: {
//         key: 'value',
//       },
//     };

//     if (prices[selectedPrice.price]?.type === 'one_time') {
//       checkoutSession.mode = 'payment';
//       checkoutSession.payment_method_types = ['card', 'sepa_debit', 'sofort'];
//     }

//     const docRef = await db
//       .collection('customers')
//       .doc(userId)
//       .collection('checkout_sessions')
//       .add(checkoutSession);

//     docRef.onSnapshot((snap) => {
//       const { error, url } = snap.data();
//       if (error) {
//         alert(`An error occurred: ${error.message}`);
//         document.querySelectorAll('button').forEach((b) => (b.disabled = false));
//       }
//       if (url) {
//         window.location.assign(url);
//       }
//     });
//   };

//   const handleBillingPortal = async () => {
//     document.querySelectorAll('button').forEach((b) => (b.disabled = true));

//     const functionRef = functions.httpsCallable('ext-firestore-stripe-subscriptions-createPortalLink');
//     const { data } = await functionRef({ returnUrl: window.location.origin });
//     window.location.assign(data.url);
//   };

//   const getCustomClaimRole = async () => {
//     await firebase.auth().currentUser.getIdToken(true);
//     const decodedToken = await firebase.auth().currentUser.getIdTokenResult();
//     return decodedToken.claims.stripeRole;
//   };

//   return (
//     <div>
//       <h1>Manage Your Subscription</h1>
//       <div id="loader">Loading...</div>
//       <main style={{ display: 'none' }}>
//         <div className="products"></div>
//         <div id="subscribe" style={{ display: 'none' }}>
//           <template id="product-template">
//             <div className="product">
//               <h2></h2>
//               <img />
//               <p className="description"></p>
//               <form>
//                 <select id="price"></select>
//                 <button type="submit">Subscribe</button>
//               </form>
//             </div>
//           </template>
//         </div>
//         <div id="my-subscription" style={{ display: 'none' }}>
//           <p></p>
//           <button onClick={handleBillingPortal}>Manage Billing</button>
//         </div>
//         <button id="signout" onClick={() => firebase.auth().signOut()}>
//           Sign Out
//         </button>
//       </main>
//     </div>
//   );
// };

// export default SubscriptionManager;
import React, { useEffect, useState } from 'react';
import firebase from 'firebase/compat/app'; // Ensure you're importing from 'compat'
import 'firebase/compat/firestore'; // Add Firestore compat import
import 'firebase/compat/functions'; // Add Functions compat import
import 'firebase/compat/auth'; // Add Auth compat import
import * as firebaseui from 'firebaseui';
import 'firebaseui/dist/firebaseui.css';

const firebaseConfig = {
    apiKey: "AIzaSyBTh2cWOzmY8wqrqeS5y-uuhnzkG115hGE",
    authDomain: "haikoo-bc326.firebaseapp.com",
    databaseURL:
      "https://haikoo-bc326-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "haikoo-bc326",
    storageBucket: "haikoo-bc326.appspot.com",
    messagingSenderId: "784052480580",
    appId: "1:784052480580:web:9a9e5e50cb8df5def9ea11",
  };
  

const functionLocation = 'us-east1';

if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
}

const db = firebase.firestore();
const functions = firebase.functions();

const SubscriptionManager = () => {
  const [currentUser, setCurrentUser] = useState(null);
  const [prices, setPrices] = useState({});
  const [products, setProducts] = useState([]);
  const [subscription, setSubscription] = useState(null);

  useEffect(() => {
    const firebaseUI = new firebaseui.auth.AuthUI(firebase.auth());
    const firebaseUiConfig = {
      callbacks: {
        signInSuccessWithAuthResult: function (authResult, redirectUrl) {
          return true;
        },
        uiShown: () => {
          document.querySelector('#loader').style.display = 'none';
        },
      },
      signInFlow: 'popup',
      signInSuccessUrl: '/',
      signInOptions: [
        firebase.auth.GoogleAuthProvider.PROVIDER_ID,
        firebase.auth.EmailAuthProvider.PROVIDER_ID,
      ],
      credentialHelper: firebaseui.auth.CredentialHelper.NONE,
      tosUrl: 'https://example.com/terms',
      privacyPolicyUrl: 'https://example.com/privacy',
    };

    firebase.auth().onAuthStateChanged((firebaseUser) => {
      if (firebaseUser) {
        document.querySelector('#loader').style.display = 'none';
        document.querySelector('main').style.display = 'block';
        setCurrentUser(firebaseUser.uid);
        startDataListeners(firebaseUser.uid);
      } else {
        document.querySelector('main').style.display = 'none';
        firebaseUI.start('#firebaseui-auth-container', firebaseUiConfig);
      }
    });
  }, []);

  const startDataListeners = (uid) => {
    db.collection('products')
      .where('active', '==', true)
      .get()
      .then((querySnapshot) => {
        const productsData = [];
        querySnapshot.forEach(async (doc) => {
          const priceSnap = await doc.ref
            .collection('prices')
            .where('active', '==', true)
            .orderBy('unit_amount')
            .get();
          const product = doc.data();
          const priceData = {};
          priceSnap.docs.forEach((priceDoc) => {
            priceData[priceDoc.id] = priceDoc.data();
          });
          productsData.push({ ...product, prices: priceData });
        });
        setProducts(productsData);
      });

    db.collection('customers')
      .doc(uid)
      .collection('subscriptions')
      .where('status', 'in', ['trialing', 'active'])
      .onSnapshot(async (snapshot) => {
        if (!snapshot.empty) {
          const subscriptionData = snapshot.docs[0].data();
          const priceData = (await subscriptionData.price.get()).data();
          setSubscription({ ...subscriptionData, priceData });
        } else {
          setSubscription(null);
        }
      });
  };

  const handleSignOut = () => {
    firebase.auth().signOut();
  };

  const handleCheckout = async (event) => {
    event.preventDefault();
    document.querySelectorAll('button').forEach((b) => (b.disabled = true));
    const formData = new FormData(event.target);
    const selectedPrice = {
      price: formData.get('price'),
    };
    if (prices[selectedPrice.price]?.recurring?.usage_type !== 'metered')
      selectedPrice.quantity = 1;
    const checkoutSession = {
      automatic_tax: true,
      tax_id_collection: true,
      collect_shipping_address: true,
      allow_promotion_codes: true,
      line_items: [selectedPrice],
      success_url: window.location.origin,
      cancel_url: window.location.origin,
      metadata: {
        key: 'value',
      },
    };
    if (prices[selectedPrice.price]?.type === 'one_time') {
      checkoutSession.mode = 'payment';
      checkoutSession.payment_method_types = ['card', 'sepa_debit', 'sofort'];
    }

    const docRef = await db
      .collection('customers')
      .doc(currentUser)
      .collection('checkout_sessions')
      .add(checkoutSession);

    docRef.onSnapshot((snap) => {
      const { error, url } = snap.data();
      if (error) {
        alert(`An error occurred: ${error.message}`);
        document.querySelectorAll('button').forEach((b) => (b.disabled = false));
      }
      if (url) {
        window.location.assign(url);
      }
    });
  };

  const handleBillingPortal = async () => {
    document.querySelectorAll('button').forEach((b) => (b.disabled = true));
    const functionRef = functions.httpsCallable('ext-firestore-stripe-subscriptions-createPortalLink');
    const { data } = await functionRef({ returnUrl: window.location.origin });
    window.location.assign(data.url);
  };

  const getCustomClaimRole = async () => {
    await firebase.auth().currentUser.getIdToken(true);
    const decodedToken = await firebase.auth().currentUser.getIdTokenResult();
    return decodedToken.claims.stripeRole;
  };

  return (
    <div>
      <h1>Manage Your Subscription</h1>
      <div id="loader">Loading...</div>
      <main style={{ display: 'none' }}>
        <div className="products">
          {products.map((product) => (
            <div key={product.name} className="product">
              <h2>{product.name.toUpperCase()}</h2>
              <img src={product.images[0]} alt={product.name} />
              <p className="description">{product.description?.toUpperCase() || ''}</p>
              <form onSubmit={handleCheckout}>
                <select id="price">
                  {Object.keys(product.prices).map((priceId) => (
                    <option key={priceId} value={priceId}>
                      {new Intl.NumberFormat('en-US', {
                        style: 'currency',
                        currency: product.prices[priceId].currency,
                      }).format((product.prices[priceId].unit_amount / 100).toFixed(2))} per {product.prices[priceId].interval ?? 'once'}
                    </option>
                  ))}
                </select>
                <button type="submit">Subscribe</button>
              </form>
            </div>
          ))}
        </div>
        <div id="subscribe" style={{ display: subscription ? 'none' : 'block' }}>
          {/* Subscription form */}
        </div>
        <div id="my-subscription" style={{ display: subscription ? 'block' : 'none' }}>
          {subscription && (
            <p>
              You are paying {new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: subscription.priceData.currency,
              }).format((subscription.priceData.unit_amount / 100).toFixed(2))} per {subscription.priceData.interval}, giving you the role: {getCustomClaimRole()}. 🥳
            </p>
          )}
        </div>
      </main>
      <button id="signout" onClick={handleSignOut}>Sign Out</button>
      <button id="billing-portal-button" onClick={handleBillingPortal}>Billing Portal</button>
      <div id="firebaseui-auth-container"></div>
    </div>
  );
};

export default SubscriptionManager;