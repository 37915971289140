// import React, { useCallback, useState, useEffect } from 'react';
// import { loadStripe } from '@stripe/stripe-js';
// import { EmbeddedCheckoutProvider, EmbeddedCheckout } from '@stripe/react-stripe-js';

// // Load your Stripe publishable key
// const stripePromise = loadStripe('pk_test_51PomwV2KqNIKpvjTmfXGqzMBFeOaL5IB6eo2JXrYQyQA6I9SpcBk0BUryvoLItEwLsIqAhJjKN32a3OAsXaDHPFV00nKPNqqnx');

// const CheckoutForm = () => {
//     const fetchClientSecret = useCallback(() => {
//         return fetch('/create-checkout-session', {
//             method: 'POST',
//         })
//         .then(res => res.json())
//         .then(data => data.clientSecret);
      
//     }, []);

//     const options = { fetchClientSecret };

//     return (
//         <div id="checkout">
//             <EmbeddedCheckoutProvider stripe={stripePromise} options={options}>
//                 <EmbeddedCheckout />
//             </EmbeddedCheckoutProvider>
//         </div>
//     );
// };

// export default CheckoutForm;

import React, { useState } from 'react';
import { getFirestore, doc, setDoc, collection } from 'firebase/firestore';
import { getFunctions, httpsCallable } from 'firebase/functions';
// import { useAuth } from '../auth'; // Assuming you have an auth context or hook
import { getAuth } from 'firebase/auth';


const SubscriptionButton = () => {
  const [loading, setLoading] = useState(false);
  const { user } = getAuth(); // Use your auth hook or context

  const createSubscription = async () => {
    setLoading(true);
    const functions = getFunctions();
    const createCheckoutSession = httpsCallable(functions, 'createCheckoutSession');
    
    try {
      const { data } = await createCheckoutSession({ priceId: 'price_1PopN02KqNIKpvjTFre0NGO5' });

      // Listen for Firestore changes
      const db = getFirestore();
      const docRef = doc(db, 'customers', user.uid, 'checkout_sessions', data.sessionId);

      docRef.onSnapshot((snap) => {
        const { error, url } = snap.data();
        if (error) {
          alert(`An error occurred: ${error.message}`);
          setLoading(false);
        }
        if (url) {
          window.location.assign(url);
        }
      });
    } catch (error) {
      console.error('Error creating subscription:', error);
      alert('Failed to create subscription');
      setLoading(false);
    }
  };

  return (
    <button onClick={createSubscription} disabled={loading}>
      {loading ? 'Loading...' : 'Subscribe Now'}
    </button>
  );
};

export default SubscriptionButton;
